<template>
  <div class="w-96">

    <div v-if="!token">
      
      <PAlert 
        class="text-center" 
        type="error"
        v-html="$tk('ResetPassword.InvalidResetLink')"
      ></PAlert>

      <div class="mt-8 flex-wrap text-center space-x-2">
        <router-link class="text-sm hover:underline" to="/forgot-password" v-html="$tk('ResetPassword.RequestNewLink')"></router-link>
      </div> 

    </div>

    <div v-else-if="success">
      
      <PAlert 
        class="text-center" 
        type="success"
        v-html="$tk('ResetPassword.PasswordChanged')"
      ></PAlert>

      <div class="mt-8 flex-wrap text-center space-x-2">
        <router-link class="text-sm hover:underline" to="/login" v-html="$tk('Common.General.GoToLogin')"></router-link>
      </div> 

    </div>

    <form v-else @submit.prevent="onSubmit">
       
      <PAlert v-if="!error"
        class="text-center"
        type="info"
      >
        <span v-html="$tk('ResetPassword.Create')"></span> <span v-if="isNew" v-html="$tk('ResetPassword.New')"></span> <span v-html="$tk('ResetPassword.PasswordFor')"></span> <span class="font-semibold">{{ login }}</span>.
      </PAlert>
      
      <PAlert v-else
        class="text-center"
        type="error"
      >
        {{ error.message }}
      </PAlert>

      <PInput 
        class="mt-4" 
        type="password" 
        :label="$tk('ResetPassword.NewPassword')" 
        v-model="password" 
        
      />

      <PInput 
        class="mt-4" 
        type="password" 
        :label="$tk('ResetPassword.ConfirmNewPassword')" 
        v-model="passwordConfirm" 
      />

      <div class="mt-8 flex items-center space-x-2">
        
        <PButton 
          type="submit" 
          color="primary" 
          :disabled="!canSubmit" 
          :loading="isSubmitting"          
        >
          <span v-html="$tk('Common.Actions.Change')"></span>
        </PButton>

        <router-link 
          to="login" 
          class="text-sm hover:underline" 
          v-html="$tk('Common.Actions.Cancel')"
        ></router-link>

      </div>
    </form>
  </div>
</template>

<script>
import http from "@/http"
import { get } from "lodash"
export default {
  
  name: "ResetPassword",
  
  data () {
    return {
      password: "",
      passwordConfirm: "",
      isSubmitting: false,
      error: null,
      success: false,
      token: undefined,
      login: ""
    }
  },
  
  computed: {
   canSubmit() {
     return this.password.length > 0 && this.passwordConfirm.length > 0
   },
   isNew () {
     return get(this.$route, "query.new") === "1"
   }
  },
  
  methods: {
    async onSubmit () {

      this.error = null

      if (this.password !== this.passwordConfirm) {
        this.error = { message: this.$tk("ResetPassword.PasswordsAreNotEqual", true) }
      } else {

        this.isSubmitting = true

        try {
          let options = {
            token: this.token,
            newPassword: this.password
          }

          await http.post("UserPassword", options)
          this.success = true

        } catch (error) {
          this.error = { message: this.$tk("ResetPassword.ErrorUpdatingPassword", true) }
        }

        this.isSubmitting = false
      }
    }
  },

  mounted () {
    this.token = this.$route.query._token
    this.login = this.$route.query._login
  }
}
</script>
